<template>
    <div class="footerCont">
    <p>
        <span id="footerContent">
            <a :href=banInvite target="_blank">Bannin' Bonnie</a> - <a :href=studioInvite target="_blank">Conway Studios</a> - All Rights Reserved  | {{year}}
        </span>
    </p>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        year: String,
        banInvite: String,
        studioInvite: String
    }
}
</script>