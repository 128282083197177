<template>
    <div class="inviteCont">
        <h1 class="inviteHead">Invite</h1>
        <div class="inviteContent">
            <p>Want to invite the bot to your server?</p>
            <p>Just click <a :href=link target="_blank">here!</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Invite',
    props: {
        link: String
    }
}
</script>