<template>
    <button class="btn" @click="btnClick()">
        <i class='fa-brands fa-discord' style="background: none !important;"></i> {{text}}
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String,
        link: String,
    },
    methods: {
        btnClick() {
            window.open(this.link)
        }
    }
}
</script>