<template>
  <img src="./assets/logo.png" class="logo">
  <Header title="Bannin' Bonnie" />
  <div class="btns">
    <Button text="Support Discord" link='https://discord.gg/YMBDWngzwR' />
  </div>
  <About />
  <Commands :commands="commands" />
  <Invite link="https://discord.com/api/oauth2/authorize?client_id=956645049372909578&permissions=277025524740&scope=bot%20applications.commands" />
  <Footer year="2022" banInvite="https://discord.gg/YMBDWngzwR" studioInvite="https://discord.gg/RdXecV5Ns2" />
</template>

<script>
import Header from './components/Header.vue'
import About from './components/About.vue'
import Commands from './components/Commands.vue'
import Invite from './components/Invite.vue'
import Footer from './components/Footer.vue'
import Button from './components/Button.vue'

export default {
  
  name: 'App',
  components: {
    Header,
    About,
    Commands,
    Invite,
    Footer,
    Button
  },
  data() {
    return {
      commands: []
    }
  },
  created() {
    this.commands = [
      {
        id: 1,
        command: '/help',
        description: 'Running this command will give you more information about both the bot and commands'
      },
      {
        id: 2,
        command: '/ban',
        description: 'The meat of the bot! This command bans a user for the reason that is given. And if stated, will also display the ban publicly',
      },
      {
        id: 3,
        command: '/config',
        description: 'This command is the command that allows you to configure the channel where bans are displayed publicly'
      },
      
      {
        id: 4,
        command: '/invite',
        description: 'This command sends a link to allow other users to invite the bot.'
      }
    ]
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    background-color: rgb(44, 44, 44);
    color: white
  }

  .btn {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    background-color: #5865F2;
    color: white;
    padding: 15px;
    border-radius: 10px;
    border: 0;
  }

  .btn:hover {
    cursor: pointer;
  }

  .logo {
    margin-top: 4em;
    width: 200px;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .header {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 100px;


    margin-top: 0.2em;
  }

  .btns {
    margin-top: 1em;
    text-align: center;
  }

  .aboutCont {
    font-family: 'Open Sans', sans-serif;

    margin-left: 250px;
    padding-right: 1000px;

    margin-top: 8em
  }

  .aboutHead, .commandsHead, .inviteHead {
    font-weight: 400;
    font-size: 75px;
  }

  .aboutContent, .command {
    font-size: 20px;
  }
  #config {
    background-color: rgba(62, 62, 62, 0.6);
    padding: 3.5px;
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    border-radius: 5px;
  }

  .commandsHead {
    font-family: 'Open Sans', sans-serif;
    text-align: right;
    margin-right: 250px;
    margin-top: 1.2em;
  }

  .commandCont {
    font-family: 'Open Sans', sans-serif;
    text-align: right;

    margin-right: 250px;
    padding-left: 1000px;
  }

  .inviteCont {
    font-family: 'Open Sans', sans-serif;

    margin-left: 250px;
    padding-right: 1000px;

    margin-top: 1.2em
  }

  .inviteContent {
    font-size: 25px;
  }

  .inviteCont a {
    color: rgb(155, 155, 155);
  }

  .footerCont {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    text-align: center;

    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }

  .footerCont a {
    color: rgb(155, 155, 155);
    background: none !important;
  }

  #footerContent {
    background-color: rgba(62, 62, 62, 0.6);
    padding: 10px;
    border-radius: 10px;
  }

</style>
