<template>
    <h1 class="commandsHead">Commands</h1>
    <div :key="command.id" v-for="command in commands">
        <Command :command="command" />
        <br>
    </div>
</template>

<script>
import Command from './Command.vue'

export default {
    name: 'Commands',
    props: {
        commands: Array
    },
    components: {
        Command
    }
}
</script>