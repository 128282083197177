<template>
    <div class="commandCont">
        <div class="command">
            <h3>{{command.command}}</h3>
            <p>{{command.description}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Command',
    props: {
        command: Object
    }
}
</script>